// import Swiper JS
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

const slides = document.querySelectorAll(".quote-slider .swiper-slide");
if (slides.length !== 0) {
  const slider = document.querySelector(".quote-slider__wrapper");
  if (slides.length > 1) {
    new Swiper(".quote-slider", {
      modules: [Navigation, Pagination],
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  } else {
    slider.classList.add("no--sliding");
  }
}
