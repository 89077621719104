import "leaflet/dist/leaflet";
import "leaflet/dist/leaflet.css";

import { getData } from "./helpers";

import setupListMapToggle from "./initiatives/toggle";
import setupList from "./initiatives/list";
import setupMap from "./initiatives/map";

initMap().catch(console.error);

async function initMap() {
  // Check if we are on the correct page
  const mapList = document.querySelector(".js-events");
  if (!mapList) {
    return;
  }

  // Get items from local storage
  let items = JSON.parse(localStorage.getItem("items"));

  // Check if there are items in local storage
  if (!items) {
    const uri = document.querySelector(".js-events").dataset.eventsUrl;
    items = await getData(uri);
    localStorage.setItem("items", JSON.stringify(items));
  }

  setupList(items);
  setupMap({ anchor: "card", items });
  setupListMapToggle();
}
