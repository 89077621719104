// import Swiper JS
import { Swiper } from "swiper";
import { Navigation, Pagination } from "swiper/modules";

new Swiper(".image-slider", {
  modules: [Navigation, Pagination],
  loop: true,
  pagination: {
    el: ".image-slider__pagination",
    type: "fraction",
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
